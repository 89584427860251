<template>
  <div>

     <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail laporan Stok Opname'">
          <template v-slot:body>
            <div class="row">
              <div class="col-2">
                <span class="d-block">Tanggal :</span>
                <span class="font-weight-bolder">{{data.checking_date}}</span>
              </div>
              <div class="col-3">
                <span class="d-block">Pegawai :</span>
                <span class="font-weight-bolder">{{data.employee_name}}</span>
              </div>
              <div class="col-2">
                <span class="d-block">Selisih(+) :</span>
                <span class="font-weight-bolder">{{parseInt(data.quantityPlus == null ? 0 : data.quantityPlus ).toLocaleString('id-ID')}}</span>
              </div>
              <div class="col-2">
                <span class="d-block">Selisih(-) :</span>
                <span class="font-weight-bolder">{{parseInt(data.quantityMinus == null ? 0 : data.quantityMinus ).toLocaleString('id-ID')}}</span>
              </div>
              <!-- <div class="col-3">
                <span class="d-block">Kerugian(Rp) :</span>
                <span class="font-weight-bolder">{{parseInt(data.amount == null ? 0 : data.amount ).toLocaleString('id-ID')}}</span>
              </div> -->
              <div class="col-3 d-flex justify-content-end">
                <b-button
                  size="sm"
                  class="mr-1 btn-outline-info"
                  @click="
                    $router.push({ path: '/inventory/stock-opname-report/print/' + data.id })
                  "
                >
                  <i class="fas fa-print px-0"></i> Print
                </b-button>
              </div>
            </div>
            <div class="row align-items-center">

                <b-table striped hover :items="items" :fields="fields">
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'actions' ? '20%' : '' }"
                    />
                  </template>
                  <template #cell(sales_price)="data">
                    <span>{{ parseInt(data.item.sales_price).toLocaleString('id-ID') }}</span>
                  </template>
                  <template #cell(lost_sales)="data">
                    <span>{{ parseInt(data.item.lost_sales).toLocaleString('id-ID') }}</span>
                  </template>
                </b-table>

            </div>
          </template>
        </Card>
      </div>
     </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {

  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: [],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "inventory_name",
          label: "Inventori",
          sortable: true,
        },
        {
          key: "current_stock",
          label: "Stok Di Sistem",
          sortable: true,
        },
        {
          key: "checking_stock",
          label: "Stok Fisik",
          sortable: true,
        },
        {
          key: "difference_stock",
          label: "Selisih Stok",
          sortable: true,
        },
      ],
      items: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
    }
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('inventory-stock-opname/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/inventory/stock-opname')
      }
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("inventory-stock-opname/" + id);
      // If Deleted
      if (result) {
        this.pagination()
        this.$router.push({path: '/inventory/stock-opname-report'})
      }
    },

    async get() {
      this.data = await module.get('inventory-stock-opname/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/stock-opnames')
      }
      this.pagination()
    },

    async pagination() {
      
      let response = await module.paginate(
        `inventory-detail-stock-opname/by-stock/report/${this.$route.params.id}`,
        ``
      );
     
      this.items = response.data;
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "2202"){
          this.btn = true
        }
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Stock Opname", route: "/inventory/stock-opname-report" },
      { title: "Detail" },
    ])

    this.get()
    this.setActiveButton()
    
  },

}
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>